var start = {
    
    functions: {
        changeContactReason: function(contact_reason_id){
            if (parseInt(contact_reason_id) === 3) {
                $('[ref="change-contact-reason"]').removeAttr('hidden');
            } else {
                $('[ref="change-contact-reason"]').attr('hidden', true);
            }
        },
        
        confirm: function(text, callback){
            swal({
                title: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Não, cancelar!'
            }).then(function() {
                callback(true);
            }, function(dismiss) {
                if (dismiss === 'cancel') {
                    callback(false);
                }
            });
        }
    },
    
    events: {
        init: function(){
            $(document).on('click', '[prevent]', function(e){
                e.preventDefault();
            });
            
            $(document).on('change', '[change-contact-reason]', function(){
                start.functions.changeContactReason($(this).val());
            });
            
            $(document).on('change', '[data-pcd]', function(){
                if (parseInt($(this).val()) === 1) {
                    start.functions.confirm('Pessoa com deficiência?', function(output){
                        if (!output) {
                            return $('[data-pcd="false"]').trigger('click');
                        }
                    });
                }
            });
        }
    },
    
    mask: {
        init: function(){
            var SPMaskBehavior = function (val) {
              return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
            };
            
            var spOptions = {
              onKeyPress: function(val, e, field, options) {
                  field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };
    
            $('#phone').mask(SPMaskBehavior, spOptions);
            $('#cpf').mask('000.000.000-00', {reverse: true});
            $('#card').mask('0000 0000 0000 0000');
            $('#expiration_date').mask('00/0000');
            $('#birth_date').mask('00/00/0000');
        }
    },
    
    init: function(){
        start.mask.init();
        start.events.init();
    }
};

start.init();